import { Component, Input, OnInit } from '@angular/core';
import { ActiviewCampaign, ActiviewLang, ActiviewStudyAxe, CampaignOnePager, KeyValue, Method, StudyType, Type } from '../../../types';
import * as _ from 'lodash';
import { CampaignService } from '../../../campaigns/campaigns.service';
import { catchError, mergeMap, take, tap } from 'rxjs/operators';
import { ReferenceTypeService } from '../../services/reference-type.service';
import { UtilService } from '../../services/util.service';
import { NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { DNATranslateService } from '../../services/translate.service';
import { forkJoin, of, throwError } from 'rxjs';
import { ErrorManagerService } from '../../services/errorManager.service';
import { environment } from '../../../../../src/environments/environment';
import { HttpRestService } from '../../services/httpRest.service';

/**
 * 20868
 */
@Component({
  selector: 'dna-actiview-data',
  templateUrl: './actiview-data.component.html',
  styleUrls: ['./actiview-data.component.less']
})
export class ActiviewDataComponent implements OnInit {

  @Input() campaignId: string;
  @Input() isVolunteer: boolean;

  showSpinner: boolean;
  studyTypes: StudyType[] = [];
  opticalCodes: Type[];
  typeCampaigns: Type[];
  methods: Method[];
  allCountries: any;
  currentLanguage: string;
  researchAxes: ActiviewStudyAxe[] = [];
  submitted: boolean = false;
  activiewCampaign: ActiviewCampaign = new ActiviewCampaign();
  error: boolean = false;
  languageMap = {
    english: ActiviewLang.english,
    french: ActiviewLang.french,
    japanese: ActiviewLang.japanese,
    chinese: ActiviewLang.chinese,
    portuguese: ActiviewLang.portuguese,
    spanish: ActiviewLang.spanish
  };

  constructor(
    private campaignService: CampaignService,
    private referenceTypeService: ReferenceTypeService,
    private utilService: UtilService,
    private activeModal: NgbActiveModal,
    private translationService: DNATranslateService,
    private errorManager: ErrorManagerService,
    private httpRestService: HttpRestService
  ) { }

  ngOnInit() {
    this.opticalCodes = this.referenceTypeService.getOpticalTypes();
    this.typeCampaigns = this.referenceTypeService.getTypeCampaign();
    this.currentLanguage = this.translationService.getLanguage();
    this.allCountries = this.utilService.setAllCountries(this.currentLanguage);
    this.showSpinner = true;
    this.error = false;
  
    const researchAxes$ = this.httpRestService.getResearchAxesData().pipe(
      tap(res => this.researchAxes = res.researchAxes),
      catchError(error => {
        console.error('Error fetching research axes data', error);
        return of([]);
      })
    );
  
    const campaignData$ = this.campaignService.getActiviewFromCampaign(this.campaignId).pipe(
      tap(activiewCampaign => {
        this.activiewCampaign = activiewCampaign;
        const selectedType = this.studyTypes?.find(type => 
          type.code === activiewCampaign.study_type.code
        );
        if (selectedType) {
          this.activiewCampaign.study_type = {
            code: selectedType.code,
            name: selectedType.name[this.languageMap[this.currentLanguage]] || selectedType.name['en'],
            is_active: selectedType.is_active
          };
        }
      })
    )
    const studyTypes$ = this.campaignService.getActiviewTypes().pipe(
      tap((studyTypes: StudyType[]) => {
        this.studyTypes = studyTypes;
        const selectedType = this.studyTypes.find(type => 
          type.code === this.activiewCampaign?.study_type?.code
        );
        if (selectedType) {
          this.activiewCampaign.study_type = {
            code: selectedType.code,
            name: selectedType.name[this.currentLanguage] || selectedType.name['en'],
            is_active: selectedType.is_active
          };
        }
      }),
      mergeMap(() => this.campaignService.getActiviewMethods()),
      mergeMap((methods) => this.referenceTypeService.dataTranslations(methods, 'key', 'value')),
      tap((methods: Method[]) => this.methods = this.utilService.sortArrayOfKeyValueObjects(methods))
    );
  
    forkJoin([researchAxes$, campaignData$, studyTypes$]).pipe(
      tap(() => this.showSpinner = false),
      catchError((error) => {
        this.showSpinner = false;
        this.error = true;
        return of([]);
      }),
      take(1)
    ).subscribe(() => { }, error => {
    })
  }

  compareKeyValue(c1: KeyValue, c2: KeyValue): boolean {
    return c2 ? c1.key === c2.key : false;
  }

  comparebyId(a: Type, b: Type): boolean {
    if (!b) return false;
    return a.id === b.id;
  }

  compareCode(c1: any, c2: any): boolean {
    return c2 ? c1.code === c2.code : false;
  }

  cancel() {
    this.activeModal.dismiss();
  }

  save(activiewCampaign: ActiviewCampaign) {
    this.submitted = true;
    this.showSpinner = true;
  
    const selectedStudyType = this.studyTypes.find(type =>
      type.code === activiewCampaign.study_type.code
    );

    const selectedResearchAxe = this.researchAxes.find(axe =>
      axe.code === activiewCampaign.research_axes.code);

    if (!selectedStudyType || !selectedStudyType.is_active) {
      this.submitted = false;
      this.showSpinner = false;
      this.errorManager.displayMessage('ERROR_INVALID_OR_INACTIVE_STUDY_TYPE', 'danger', { timeOut: 10000 }, true);
      return false;
    }

    if (!selectedResearchAxe && !_.isEmpty(activiewCampaign.research_axes)) {
      this.submitted = false;
      this.showSpinner = false;
      this.errorManager.displayMessage('ERROR_INVALID_RESEARCH_AXIS', 'danger', { timeOut: 10000 }, true);
      return false;
    }

    if (!activiewCampaign.estimated_contribution) {
      this.submitted = false;
      this.showSpinner = false;
      this.errorManager.displayMessage('ERROR_INVALID_ESTIMATED_CONTRIBUTION', 'danger', { timeOut: 10000 }, true);
      return false;
    }
  
    this.campaignService.postActiviewData(this.campaignId, activiewCampaign).pipe(
      catchError(err => {
        this.showSpinner = false;
        this.submitted = false;
        this.errorManager.displayMessage('ON_ERROR_UPDATE', 'danger');
        return throwError(err);
      }),
      tap(() => {
        this.submitted = false;
        this.showSpinner = false;
      }),
      tap(() => this.activeModal.close())
    ).subscribe()
  }

  goToActiview(id) {
    window.open(environment.actiview_url() + id)
  }

}