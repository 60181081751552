
import {throwError as observableThrowError,
  Observable
} from 'rxjs';

import {map, catchError, tap, mergeMap} from 'rxjs/operators';
import {
  ActivatedRoute,
  Router,
  Params
} from '@angular/router';
import {
  Component,
  OnInit
} from '@angular/core';

import * as _ from 'lodash';
import * as moment from 'moment';
import {
  NgbDateStruct
} from '@ng-bootstrap/ng-bootstrap';

import {
  ARCSMetiers,
  ArcsPanelist,
  AttributeData,
  AttributeDetails,
  Classifications,
  DNAClass,
  FicheCaracterisation,
  Metier,
  Metiers,
  Panelist,
  TimelineTypes,
  AttributeDataCategories,
  HubsById,
} from '../../../types';
import {
  ErrorManagerService
} from '../../../shared/services/errorManager.service';
import {
  HttpRestService
} from '../../../shared/services/httpRest.service';
import {
  UserService
} from '../../../shared/services/user.service';
import {
  VolunteerService
} from '../../volunteers.service';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'dna-fiche-caracterisation',
  templateUrl: 'FicheCaracterisation.component.html',
  styleUrls: ['./FicheCaracterisation.less'],
})

export class FicheCaracterisationComponent implements OnInit {

  arcsPanelist: ArcsPanelist;
  AttributeData: AttributeData[];
  AttributeDataCategories: AttributeDataCategories[] = [];
  isEditable: boolean = false;
  metier: Metier;
  metiers: typeof Metiers = Metiers;
  panelist: Panelist;
  params: any;
  showSpinner: boolean = true;
  bridgeFiche: string;
  bridgeParam: string;
  refPanelist: string;

  date: {
    [attributeName: string]: NgbDateStruct;
  } = {};
  multiple: {
    [attributeName: string]: AttributeDetails[];
  } = {};

  constructor(
    private activatedRoute: ActivatedRoute,
    private errorManagerService: ErrorManagerService,
    private httpRestService: HttpRestService,
    private router: Router,
    private userService: UserService,
    private volunteerService: VolunteerService,
    private translate: TranslateService
  ) { }

  ngOnInit() {
    this.metier = this.userService.getUser().currentWorkspace.metier;
    this.panelist = this.volunteerService.getPanelist();
    this.refPanelist = /^[0-9]+$/.test(this.panelist.panelistNumber) ? 'arcs' : 'horsarcs';
    this.activatedRoute.params.pipe(
      tap(p => this.initBridge(p)),
      mergeMap(p => this.getAttributeData(p.idFiche)),
      catchError(err => {
        this.goToGeneralInfo();
        this.errorManagerService.displayMessage(err.error, 'danger', {}, false);
        return observableThrowError(err);
      }),
      tap(this.initData),
      tap(a => this.AttributeData = a),
      tap(() => this.AttributeDataCategories = this.categorize(this.AttributeData)),
      tap(() => this.initBresilAttributes()),
      tap(() => this.initHairDate()),
      tap(() => this.showSpinner = false))
      .subscribe();
  }

  initBresilAttributes() {
    if (this.panelist.hub === HubsById.Brazil) {
      this.AttributeDataCategories.forEach(ad => {
        ad.data = _.sortBy(ad.data.filter(d => d.AttributeType !== 8), ['AttributeName']);
      });
    }
  }

  initHairDate() {
    if (this.panelist.hub === HubsById.France) {
      let expertAD = this.AttributeDataCategories.find(adc => adc.category === 'EXPERT')
      if (!_.isUndefined(expertAD)) {
        let indexOfAttributDate = expertAD.data.findIndex(d => d.AttributeID === 758);
        if (indexOfAttributDate !== -1) {
          expertAD.data.unshift(expertAD.data.find(d => d.AttributeID === 758));
          expertAD.data.splice(indexOfAttributDate + 1, 1)
        }
      }
    }
  }

  initBridge(params: Params) {
    this.bridgeParam = _.get(params, 'bridgeNumber', '');
    this.bridgeFiche = _.cloneDeep(this.bridgeParam);
  }

  categorize(data: AttributeData[]): AttributeDataCategories[] {
    data = data.map(d => d);// Make copy to not update this.attributeData
    let dataCategories: AttributeDataCategories[] = [];
    dataCategories.push({
      category: 'EXPERT',
      data: _.remove(data, d => d.AttributeName.indexOf('EXPERT') !== -1)
    })
    dataCategories.push({
      category: 'AGENCE',
      data: _.remove(data, d => d.AttributeName.indexOf('AGENCE') !== -1)
    });
    dataCategories.unshift({
      category: '',
      data: data
    })

    return dataCategories;
  }

  isLocked(detail: AttributeData) {
    return detail.AttributeID === 129;
  }

  filterByWorkspace(data: Classifications[]): AttributeData[] {
    let attribData: AttributeData[];
    if (this.metier) {
      switch (this.metier.name) {
        case this.metiers.Hair:
          let indexHair = _.findIndex(data, classif => classif.Name.toLowerCase() === DNAClass.DNA_API_HAIR_CLASS.toLowerCase())
          if (indexHair > -1) {
            attribData = data[indexHair].AttributeData;
          }
          break;
        case this.metiers.Skin:
          let indexSkin = _.findIndex(data, classif => classif.Name.toLowerCase() === DNAClass.DNA_API_SKIN_CLASS.toLowerCase());
          let indexMakeUp = _.findIndex(data, classif => classif.Name.toLowerCase() === DNAClass.DNA_API_MAKEUP_CLASS.toLowerCase());
          if (indexSkin > -1 && indexMakeUp > -1) {
            attribData = _.uniqBy(data[indexSkin].AttributeData.concat(data[indexMakeUp].AttributeData), 'AttributeID');
          }
          else if (indexSkin > -1) {
            attribData = data[indexSkin].AttributeData;
          }
          else if (indexMakeUp > -1) {
            attribData = data[indexMakeUp].AttributeData;
          }
          break;
      }
    }
    if (_.isUndefined(attribData)) {
      attribData = _.find(data, classif => classif.Name.toLowerCase() === DNAClass.DNA_API_CLASS.toLowerCase()).AttributeData;
      if (this.metier) {
        switch (this.metier.name) {
          case this.metiers.Hair:
            const regHair = new RegExp(`^${ARCSMetiers.Hair}`);
            attribData = attribData.filter(d => d.AttributeName.match(regHair));
            break;
          case this.metiers.Skin:
            const regSkin = new RegExp(`^${ARCSMetiers.Skin}`);
            attribData = attribData.filter(d => d.AttributeName.match(regSkin));
            break;
        }
      }
    }
    return attribData;
  }

  changeAttributeData(attributeDetails: AttributeDetails, attributeData: AttributeData) {
    attributeData.HasValue = true;
    if (!attributeDetails) {
      attributeData.Value = null;
      attributeData.AttributeDetails.map(attributeDetail => attributeDetail.IsValue = false);
    } else {
      attributeData.Value = attributeDetails.Code;
      attributeData.AttributeDetails.map(attributeDetail => attributeDetail.IsValue = attributeData.Value === attributeDetail.Code ? true : false);
    }
  }

  changeTags(attributeDetails: AttributeDetails, attributeData: AttributeData) {
    attributeData.HasValue = true;
    if (!attributeDetails) {
      attributeData.Value = null;
      attributeData.AttributeDetails.map(attributeDetail => attributeDetail.IsValue = false);
    } else {
      attributeData.Value = attributeDetails.Code;
      attributeData.AttributeDetails.map(attributeDetail => attributeDetail.IsValue = attributeData.Value === attributeDetail.Code ? true : false);
    }
  }

  compareByCode(a: AttributeDetails, b: AttributeData) {
    return a && b && a.Code === b.Value;
  }

  getAttributeData(id: string): Observable<AttributeData[]> {
    if (id === 'last') {
      this.isEditable = true;
      let call: Observable<ArcsPanelist>;
      if (this.refPanelist === 'arcs') {
        call = this.httpRestService.getPanelistInformation(this.panelist.hub, this.panelist.panelistNumber);
      } else {
        call = this.httpRestService.getPanelistHorsArcsInformation(this.panelist.hub, this.panelist.panelistNumber).pipe(
          catchError(err => {
            let msg = 'An error occurred while retrieving the characterization form. ';
            if (err && err.error && err.errors && err.error.errors[0]) {
              msg += err.error.errors[0].reason;
              console.log(err.error.errors[0].reason);
            }
            return observableThrowError({error: msg});
          })
        );
      }

      return call.pipe(
        tap(p => this.arcsPanelist = p),
        map(p => this.filterByWorkspace(p.classifications)));
    } else {
      return this.volunteerService.getFicheCaracterisation(id, this.panelist.hub).pipe(
        tap((f: FicheCaracterisation)=> this.bridgeFiche = f.requestNumber),
        map((f: FicheCaracterisation) => f.AttributeData));
    }
  }

  getDateObject(date: Date): NgbDateStruct {
    return {
      year: date.getFullYear(),
      month: date.getMonth() + 1,
      day: date.getDate()
    };
  }

  goToGeneralInfo() {
    let path =['volunteers', this.panelist.panelistNumber, this.panelist.hub, 'generalInformation'];
    if (this.bridgeParam) {
      path.push(this.bridgeParam);
    }
    this.router.navigate(path);
  }

  initData = (attributeData: AttributeData[]) => {
    attributeData.map(attribute => {
      attribute.HasValue = false;
      switch (attribute.AttributeType) {
        case 3:
          this.initDate(attribute);
          break;
        case 5:
          this.initMultiple(attribute);
          break;
      }
    });
  };

  initDate(attributeData: AttributeData) {
    if (attributeData.Value) {
      let date = moment(attributeData.Value, 'MMM D YYYY h:mmA').toDate();
      this.date[attributeData.AttributeID] = this.getDateObject(new Date(date));
    }
  }

  initMultiple(attributeData: AttributeData) {
    this.multiple[attributeData.AttributeID] = attributeData.AttributeDetails.reduce((array: any[], value: AttributeDetails) => {
      if (value.IsValue) {
        array.push(value);
      }
      return array;
    }, []);
  }

  /**
   * Permet de gérer les multiples classifications
   * @param attributeData un objet contenant tous les attributsData pêle-mêle
   * @param classifications les classifs à modifier en fonction des attributData
   * @return classifications - les classifs modifiées
   */
  private putAttributeData(attributeData: AttributeData[], classifications: Classifications[]): Classifications[] {
    if (classifications.length > 1) {
      classifications.forEach((classif: Classifications) => {
        classif.AttributeData.forEach((attribute: AttributeData) => {
          let modifiedAttribute = attributeData.find((attr: AttributeData) => attr.AttributeID === attribute.AttributeID);
          if (modifiedAttribute) {
            attribute = modifiedAttribute;
          }
        });
      });
    } else {
      classifications[0].AttributeData = attributeData;
    }
    return classifications;
  }

  onDateChanged(date: NgbDateStruct, attributeId: number) {
    const dateUTC = new Date(Date.UTC(date.year, date.month - 1, date.day));
    let attribute = this.AttributeData.find(a => a.AttributeID === attributeId);
    attribute.HasValue = true;
    if (moment(dateUTC).isValid()) {
      attribute.Value = moment(dateUTC).format('MMM D YYYY h:mmA');
    } else {
      attribute.Value = null;
    }
  }

  save() {
    this.showSpinner = true;

    if (this.bridgeFiche) {
      this.bridgeFiche = this.bridgeFiche.toUpperCase();

      const regex = /^EV\d{4}-\d{4}$/;
      if (!regex.test(this.bridgeFiche)) {
        this.showSpinner = false;
        this.errorManagerService.displayMessage('ON_ERROR_REQUEST_NUMBER_FORMAT', 'danger');
        return;
      }
    }

    this.updateMultiple();

    if (this.arcsPanelist && this.refPanelist === 'arcs') {
      this.httpRestService.patchPanelistInformation(this.panelist.hub, this.panelist.panelistNumber, this.putAttributeData(this.AttributeData, this.arcsPanelist.classifications))
        .subscribe();
    }
    const ficheCarac = new FicheCaracterisation(this.AttributeData, this.panelist.id);
    ficheCarac.metier = this.metier;
    ficheCarac.hub = this.panelist.hub;
    ficheCarac.requestNumber = this.bridgeFiche;
    this.volunteerService.postFicheCaracterisation(this.panelist.panelistNumber, this.panelist.hub, ficheCarac, this.refPanelist).pipe(
      catchError(err => {
        this.showSpinner = false;
        this.errorManagerService.displayMessage('ON_ERROR_UPDATE', 'danger');
        return observableThrowError(err);
      }),
      tap(fiche => {
        this.panelist.timeline.push(this.volunteerService.getFicheLight(fiche))
        this.volunteerService.setPanelist(this.panelist);
      }),
      tap(() => this.showSpinner = false),
      tap(() => this.goToGeneralInfo()),)
      .subscribe();
  }

  onStringChanged(string: string, data: AttributeData) {
    data.HasValue = string !== "";
  }

  onChangeBoolean(data: AttributeData) {
    data.Value = data.Value === 'False' ? 'True': 'False';
    data.HasValue = data.Value === 'True';
  }

  updateMultiple() {
    this.AttributeData.map(a => {
      if (a.AttributeType === 5) {
        let newValue;
        if (this.multiple[a.AttributeID].length === 0) {
          newValue = null;
        } else {
          let values = [];
          for (let attribute of a.AttributeDetails) {
            let data = this.multiple[a.AttributeID].find(d => d.Code === attribute.Code);
            if (data) {
              values.push(attribute.Code);
              attribute.IsValue = true;
            } else {
              attribute.IsValue = false;
            }
          }
          newValue = values.join(",");
        }
        if (a.Value !== newValue) {
          a.HasValue = true;
          a.Value = newValue;
        }
      }
    });
  }

}
